'use client'

import React, { createContext, useContext, useState, ReactNode } from 'react';
import { LocationContextType } from '@/types/index';
import { HqLocation } from '@prisma/client';

const LocationContext = createContext<LocationContextType | undefined>(undefined);

export const LocationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [location, setLocation] = useState<HqLocation>();

  return (
    <LocationContext.Provider value={{ 
      location, 
      setLocation, 
    }}>
      {children}
    </LocationContext.Provider>
  );
};

export const useLocation = (): LocationContextType => {
  const context = useContext(LocationContext);
  if (context === undefined) {
    throw new Error('useLocation must be used within an LocationProvider');
  }
  return context;
};
