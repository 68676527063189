'use client'

import React, { useState } from 'react';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./ui/select";
import ReactCountryFlag from "react-country-flag";
import { locales } from "@/config";
import { useLocale, useTranslations } from 'next-intl';
import { Link, usePathname } from "@/i18n/routing";
import { useSearchParams } from 'next/navigation';
import { Button } from "./ui/button";

export default function LocaleSwitcher() {
  const t = useTranslations('LocaleSwitcher');
  const locale = useLocale();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [open, setOpen] = useState(false);

  const localeFlags: { [key: string]: { code: string; label: string } } = {
    en: { code: 'US', label: 'English' },
    es: { code: 'ES', label: 'Español' },
    nl: { code: 'NL', label: 'Dutch' }
  };

  const createQueryString = (params: URLSearchParams) => {
    const queryString = params.toString();
    return queryString ? `?${queryString}` : '';
  };

  const handleLocaleChange = () => {
    setOpen(false);
  };


  return (
    <Select
      value={locale}
      aria-label={t('selectLocale')}
      open={open}
      onOpenChange={setOpen}
    >
      <SelectTrigger className="flex w-fit z-50 bg-purple" arrowIconWhite purpleTheme={true} >
        <SelectValue className="">
          <div className="flex gap-2">
            <ReactCountryFlag
              countryCode={localeFlags[locale].code}
              svg
              style={{ width: '1.5em', height: '1.5em' }}
              aria-label={localeFlags[locale].label}
            />
            <span className="text-purple-foreground">{localeFlags[locale].label}</span>
          </div>
        </SelectValue>
      </SelectTrigger>
      <SelectContent className="p-0">
        {locales.map((cur) => (
          <Link
            href={`${pathname}${createQueryString(searchParams)}`}
            key={cur}
            locale={cur}
            className="flex bg-primary text-purple-foreground items-center gap-4 px-2 py-1.5 text-sm hover:cursor-pointer hover:bg-accent hover:text-accent-foreground rounded-none"
            onClick={() => handleLocaleChange()}
          >
            <ReactCountryFlag
              countryCode={localeFlags[cur].code}
              svg
              style={{ width: '1.5em', height: '1.5em' }}
              aria-label={localeFlags[cur].label}
            />
            <span>{localeFlags[cur].label}</span>
          </Link>
        ))}
      </SelectContent>
    </Select>
  );
}