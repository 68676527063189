'use client';
import React from 'react';
import { sendGTMEvent } from "@next/third-parties/google";
import { Button } from "./ui/button";
import { useTranslations } from "next-intl";
import { useRouter } from "@/i18n/routing"

const LoginButton = () => {
  const router = useRouter();
  const t = useTranslations('Profile');

  return (
    <Button onClick={() => {
      sendGTMEvent("loginButtonClicked");
      router.push(`/login`);
    }}>
      {t('login')}
    </Button>
  );
};

export default LoginButton;
