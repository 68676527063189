'use client'
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from "@/components/ui/dropdown-menu"
import { Button } from "@/components/ui/button"
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar"
import { useTranslations } from "next-intl"
import { UserProfiles } from "@prisma/client"
import { signOutAction } from "../../actions"
import { UserIcon, GiftIcon, LogOutIcon, AreaChart } from "lucide-react"
import { useRouter } from "@/i18n/routing"
import { AuthPayload } from "@/types"

interface Props {
  authPayload: AuthPayload;
}

export function ProfileButton({ authPayload }: Props) {
  const t = useTranslations('Profile')
  const router = useRouter();

  const handleNavigation = (path: string) => {
    router.push(path)
  }

  const handleSignOut = async () => {
    try {
      await signOutAction(); 
      router.refresh();      
    } catch (error) {
      console.error("Sign out failed:", error);
    }
  }

  const menuItems = [
    {
      icon: UserIcon,
      label: 'manageProfile',
      action: () => handleNavigation('/protected/profile'),
    },
    ...((authPayload.role === 'TENANT_ADMIN' || authPayload.role === 'ADMIN' ) ? [
      {
        icon: AreaChart,
        label: 'dashboard',
        action: () => handleNavigation('/protected/dashboard'),
      }
    ] : []),
    {
      icon: GiftIcon,
      label: 'myTrips',
      action: () => handleNavigation('/protected/my-trips'),
    },
    {
      icon: LogOutIcon,
      label: 'signOut',
      action: handleSignOut,
      isForm: false,
    },
  ]

  const getInitials = (firstName?: string, lastName?: string) => {
    if (firstName || lastName) {
      const initials = `${firstName ?? ''} ${lastName ?? ''}`
        .trim()
        .split(' ')
        .filter(part => part.length > 0)
        .map(part => part[0])
        .join('')
        .toUpperCase()
      return initials || 'U' // Default to 'U' if initials are empty
    }
    return ''
  }

  const hasValidNames = authPayload.firstName || authPayload.lastName

  return (
    <div className="flex items-center space-x-2">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button 
            variant="ghost" 
            className="w-10 h-10 p-0 rounded-full hover:bg-transparent focus:bg-transparent group"
            aria-label={`Profile menu for ${authPayload.firstName ?? ''} ${authPayload.lastName ?? ''}`}
          >
            <Avatar className="w-full h-full transition-transform group-hover:scale-110 group-focus:scale-110">
              <AvatarImage 
                src={authPayload.avatarUrl || undefined} 
                alt={`${authPayload.firstName ?? 'User'} ${authPayload.lastName ?? ''}`}
                className="object-cover"
              />
              <AvatarFallback className="bg-primary text-primary-foreground group-hover:bg-primary/90 group-focus:bg-primary/90 transition-colors flex items-center justify-center">
                {hasValidNames ? (
                  getInitials(authPayload.firstName, authPayload.lastName)
                ) : (
                  <UserIcon className="w-4 h-4" />
                )}
              </AvatarFallback>
            </Avatar>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56 mt-2 bg-white shadow-lg rounded-md">
          {menuItems.map((item, index) => (
            <DropdownMenuItem
              key={index}
              className="flex items-center px-3 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900 focus:outline-none cursor-pointer transition-colors duration-150"
              onClick={!item.isForm ? item.action : undefined}
              asChild={item.isForm}
            >
              {item.isForm ? (
                <form action={item.action} className="w-full">
                  <button type="submit" className="flex items-center w-full text-left">
                    <item.icon className="mr-2 h-4 w-4" />
                    <span>{t(item.label)}</span>
                  </button>
                </form>
              ) : (
                <>
                  <item.icon className="mr-2 h-4 w-4" />
                  <span>{t(item.label)}</span>
                </>
              )}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}
