'use client'

import React, { createContext, useContext, useState, ReactNode } from 'react';
import { SortingContextType, SortingMethod } from '@/types/index';

const SortingContext = createContext<SortingContextType | undefined>(undefined);

export const SortingProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [sortingMethod, setSortingMethod] = useState<SortingMethod>("ascending");

  return (
    <SortingContext.Provider value={{ 
      sortingMethod, 
      setSortingMethod, 
    }}>
      {children}
    </SortingContext.Provider>
  );
};

export const useSorting = (): SortingContextType => {
  const context = useContext(SortingContext);
  if (context === undefined) {
    throw new Error('useSorting must be used within an SortingProvider');
  }
  return context;
};
