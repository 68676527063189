'use client';

import { Tenant } from "@prisma/client";
import { ReactNode } from "react";
import { AppProvider } from "../../components/homepage/AppProvider";
import { CurrencyProvider } from "./_providers/CurrencyContext";
import { EditBookingProvider } from "./_providers/EditBookingContext";
import { LocationProvider } from "./_providers/LocationContext";
import { PlaceProvider } from "./_providers/PlaceContext";
import { SecurityDepositsProvider } from "./_providers/SecurityDepositsContext";
import { SortingProvider } from "./_providers/SortingContext";
import { TenantsProvider } from "./_providers/TenantContext";
import { NuqsAdapter } from 'nuqs/adapters/next/app'

interface Props {
  children: ReactNode;
  initialTenants: Tenant[];
}

export function Providers({ children, initialTenants }: Props) {
  return (
    <AppProvider>
      <NuqsAdapter>
        <PlaceProvider>
          <TenantsProvider initialTenants={initialTenants}>
            <LocationProvider>
              <CurrencyProvider>
                <SecurityDepositsProvider>
                  <SortingProvider>
                    <EditBookingProvider>
                      {children}
                    </EditBookingProvider>
                  </SortingProvider>
                </SecurityDepositsProvider>
              </CurrencyProvider>
            </LocationProvider>
          </TenantsProvider>
        </PlaceProvider>
      </NuqsAdapter>
    </AppProvider>
  );
}
